<template>
  <div id="yangyangju">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {

  },
}
</script>
<style lang="less" scoped>
#yangyangju {
  width: 100%;
}
</style>
<style src='./assets/style/rest.css'></style>
