import axios from "./request.js";

function getshare(data) {
  return axios({
    url: "/index.php/api/getshare",
    method: "post",
    data,
  });
}

function testinfo(data) {
  return axios({
    url: "/index.php/api/testinfo",
    method: "post",
    data,
  });
}

function weblogin(data) {
  return axios({
    url: "/index.php/api/weblogin",
    method: "post",
    data,
  });
}

function getCode(data) {
  return axios({
    url: "/index.php/api/getCode",
    method: "post",
    data,
  });
}

function web_register(data) {
  return axios({
    url: "/index.php/api/web_register",
    method: "post",
    data,
  });
}

function web_findpwd(data) {
  return axios({
    url: "/index.php/api/web_findpwd",
    method: "post",
    data,
  });
}

function webindex(data) {
  return axios({
    url: "/index.php/api/webindex",
    method: "post",
    data,
  });
}

function get_goodstype(data) {
  return axios({
    url: "/index.php/api/get_goodstype",
    method: "post",
    data,
  });
}

function type_list(data) {
  return axios({
    url: "/index.php/api/type_list",
    method: "post",
    data,
  });
}

function search(data) {
  return axios({
    url: "/index.php/api/search",
    method: "post",
    data,
  });
}

function webgoods_info(data) {
  return axios({
    url: "/index.php/api/webgoods_info",
    method: "post",
    data,
  });
}

function web_order(data) {
  return axios({
    url: "/index.php/api/web_order",
    method: "post",
    data,
  });
}

function shopindex(data) {
  return axios({
    url: "/index.php/api/shopindex",
    method: "post",
    data,
  });
}

function get_webtype(data) {
  return axios({
    url: "/index.php/api/get_webtype",
    method: "post",
    data,
  });
}

function uploadImg(data, onUploadProgress) {
  return axios({
    url: "/index.php/uploadImg",
    method: "post",
    data,
    onUploadProgress,
  });
}

function upload_web(data, onUploadProgress) {
  return axios({
    url: "/index.php/api/upload_web",
    method: "post",
    data,
    onUploadProgress,
  });
}

function shop_goodsinfo(data) {
  return axios({
    url: "/index.php/api/shop_goodsinfo",
    method: "post",
    data,
  });
}

function add_webgoods(data) {
  return axios({
    url: "/index.php/api/add_webgoods",
    method: "post",
    data,
  });
}

function edit_webgoods(data) {
  return axios({
    url: "/index.php/api/edit_webgoods",
    method: "post",
    data,
  });
}

function web_userinfo(data) {
  return axios({
    url: "/index.php/api/web_userinfo",
    method: "post",
    data,
  });
}

function get_usershopinfo(data) {
  return axios({
    url: "/index.php/api/get_usershopinfo",
    method: "post",
    data,
  });
}

function userinfo_edit(data) {
  return axios({
    url: "/index.php/api/userinfo_edit",
    method: "post",
    data,
  });
}

function rzinfo(data) {
  return axios({
    url: "/index.php/api/rzinfo",
    method: "post",
    data,
  });
}

function web_auth(data) {
  return axios({
    url: "/index.php/api/web_auth",
    method: "post",
    data,
  });
}

function web_complaint(data) {
  return axios({
    url: "/index.php/api/web_complaint",
    method: "post",
    data,
  });
}

function systemList(data) {
  return axios({
    url: "/index.php/api/systemList",
    method: "post",
    data,
  });
}

function systemallDel(data) {
  return axios({
    url: "/index.php/api/systemallDel",
    method: "post",
    data,
  });
}

function readsystem(data) {
  return axios({
    url: "/index.php/api/readsystem",
    method: "post",
    data,
  });
}

function systemDet(data) {
  return axios({
    url: "/index.php/api/systemDet",
    method: "post",
    data,
  });
}

function helpList(data) {
  return axios({
    url: "/index.php/api/helpList",
    method: "post",
    data,
  });
}

function helpDet(data) {
  return axios({
    url: "/index.php/api/helpDet",
    method: "post",
    data,
  });
}

function webbalanceLog(data) {
  return axios({
    url: "/index.php/api/webbalanceLog",
    method: "post",
    data,
  });
}

function download_file(data) {
  return axios({
    url: "/index.php/api/download_file",
    method: "post",
    data,
  });
}

function webintegralLog(data) {
  return axios({
    url: "/index.php/api/webintegralLog",
    method: "post",
    data,
  });
}

function shop_goods(data) {
  return axios({
    url: "/index.php/api/shop_goods",
    method: "post",
    data,
  });
}

function cancel_webgoods(data) {
  return axios({
    url: "/index.php/api/cancel_webgoods",
    method: "post",
    data,
  });
}

function shelf_webgoods(data) {
  return axios({
    url: "/index.php/api/shelf_webgoods",
    method: "post",
    data,
  });
}

function del_webgoods(data) {
  return axios({
    url: "/index.php/api/del_webgoods",
    method: "post",
    data,
  });
}

function shop_url(data) {
  return axios({
    url: "/index.php/api/shop_url",
    method: "post",
    data,
  });
}

function set_shop_url(data) {
  return axios({
    url: "/index.php/api/set_shop_url",
    method: "post",
    data,
  });
}

function shopinfo_edit(data) {
  return axios({
    url: "/index.php/api/shopinfo_edit",
    method: "post",
    data,
  });
}

function brandlist(data) {
  return axios({
    url: "/index.php/api/brandlist",
    method: "post",
    data,
  });
}
function web_regamount(data) {
  return axios({
    url: "/index.php/api/web_regamount",
    method: "post",
    data,
  });
}
function web_getmoney(data) {
  return axios({
    url: "/index.php/api/web_getmoney",
    method: "post",
    data,
  });
}
function web_integ_recharge(data) {
  return axios({
    url: "/index.php/api/web_integ_recharge",
    method: "post",
    data,
  });
}
function web_money_recharge(data) {
  return axios({
    url: "/index.php/api/web_money_recharge",
    method: "post",
    data,
  });
}
function recharge_status(data) {
  return axios({
    url: "/index.php/api/recharge_status",
    method: "post",
    data,
  });
}
function obtain_code(data) {
  return axios({
    url: "/index.php/api/obtain_code",
    method: "post",
    data,
  });
}
function wxlogin_notify(data) {
  return axios({
    url: "/index.php/api/wxlogin_notify",
    method: "post",
    data,
  });
}
function webmyorder(data) {
  return axios({
    url: "/index.php/api/webmyorder",
    method: "post",
    data,
  });
}
function webmyorder_info(data) {
  return axios({
    url: "/index.php/api/webmyorder_info",
    method: "post",
    data,
  });
}
function getuser_phone(data) {
  return axios({
    url: "/index.php/api/getuser_phone",
    method: "post",
    data,
  });
}
function getuser_code(data) {
  return axios({
    url: "/index.php/api/getuser_code",
    method: "post",
    data,
  });
}
function payPwd(data) {
  return axios({
    url: "/index.php/api/payPwd",
    method: "post",
    data,
  });
}
function upPwd(data) {
  return axios({
    url: "/index.php/api/upPwd",
    method: "post",
    data,
  });
}
function withdraw(data) {
  return axios({
    url: "/index.php/api/withdraw",
    method: "post",
    data,
  });
}
function exchangeInt(data) {
  return axios({
    url: "/index.php/api/exchangeInt",
    method: "post",
    data,
  });
}
function loginlog(data) {
  return axios({
    url: "/index.php/api/loginlog",
    method: "post",
    data,
  });
}
function webCancellation(data) {
  return axios({
    url: "/index.php/api/web_cancellation",
    method: "post",
    data,
  });
}
function studyType(data) {
  return axios({
    url: "/index.php/api/study_type",
    method: "post",
    data,
  });
}
function studyList(data) {
  return axios({
    url: "/index.php/api/study_list",
    method: "post",
    data,
  });
}
export default {
  exchangeInt,
  withdraw,
  upPwd,
  payPwd,
  getuser_code,
  getuser_phone,
  webmyorder_info,
  webmyorder,
  wxlogin_notify,
  obtain_code,
  recharge_status,
  web_money_recharge,
  web_integ_recharge,
  web_regamount,
  web_getmoney,
  getshare,
  testinfo,
  weblogin,
  getCode,
  web_register,
  web_findpwd,
  webindex,
  get_goodstype,
  type_list,
  search,
  webgoods_info,
  web_order,
  shopindex,
  get_webtype,
  uploadImg,
  upload_web,
  shop_goodsinfo,
  add_webgoods,
  edit_webgoods,
  web_userinfo,
  get_usershopinfo,
  userinfo_edit,
  rzinfo,
  web_auth,
  web_complaint,
  systemList,
  systemallDel,
  readsystem,
  systemDet,
  helpList,
  helpDet,
  webbalanceLog,
  download_file,
  webintegralLog,
  shop_goods,
  cancel_webgoods,
  shelf_webgoods,
  del_webgoods,
  shop_url,
  set_shop_url,
  shopinfo_edit,
  brandlist,
  loginlog,
  webCancellation,
  studyType,
  studyList,
};
