import axios from 'axios'
import store from '../store'
import router from '../router'
// import {
// 	Message
// } from 'element-ui'
import baseUrl from './baseUrl.js'
// 配置请求默认接口，环境切换
if (process.env.NODE_ENV === 'development') {
	// 开发环境接口
	axios.defaults.baseURL = baseUrl.Base_dev_URL
} else if (process.env.NODE_ENV === 'production') {
	// 生产环境接口
	axios.defaults.baseURL = baseUrl.Base_pro_URL
}
// 设置请求超时时间
axios.defaults.timeout = 300000
// 是否允许跨域携带cookie信息
axios.defaults.withCredentials = false;
// 配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 拦截器 添加一个请求拦截器
axios.interceptors.request.use((config) => {
	config.headers["token"] = localStorage.token?localStorage.token:''
  if (config.url === '/index.php/uploadImg' || config.url === '/index.php/api/upload_web') {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
	return config;
}, (error) => {
	// 请求出错
	return Promise.reject(error);
});

// 拦截器 添加一个响应拦截器
axios.interceptors.response.use((response) => {
	if (response.status === 200) {
		return response.data
	} else {
		return Promise.reject(response);
	}
}, (error) => {
	console.log(error)
})
function http(config) {
  if (config.method.toLowerCase() == 'get') {
		config.params = config.data
  }
  return axios(config)
}
export default http;
