import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => {});
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/white",
    name: "white",
    component: () => import("@/views/white.vue"),
  },
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index.vue"),
    redirect: {
      name: "home",
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/main/home.vue"),
      },
      {
        path: "/homeNav",
        name: "homeNav",
        component: () => import("@/views/main/homeNav.vue"),
      },
      {
        path: "/homeSpan",
        name: "homeSpan",
        component: () => import("@/views/main/homeSpan.vue"),
      },
      {
        path: "/details",
        name: "details",
        component: () => import("@/views/main/details.vue"),
      },
      {
        path: "/shopIndex",
        name: "shopIndex",
        component: () => import("@/views/main/shopIndex.vue"),
      },
      {
        path: "/study",
        name: "study",
        component: () => import("@/views/main/study.vue"),
      },
    ],
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search.vue"),
  },
  {
    path: "/release",
    name: "release",
    component: () => import("@/views/release.vue"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("@/views/personal.vue"),
    redirect: {
      name: "personalHomepage",
    },
    children: [
      {
        path: "/personalHomepage",
        name: "personalHomepage",
        component: () => import("@/views/user/personalHomepage.vue"),
      },
      {
        path: "/realName",
        name: "realName",
        component: () => import("@/views/user/realName.vue"),
      },
      {
        path: "/report",
        name: "report",
        component: () => import("@/views/user/report.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/user/news.vue"),
      },
      {
        path: "/helpCenter",
        name: "helpCenter",
        component: () => import("@/views/user/helpCenter.vue"),
      },
      {
        path: "/balance",
        name: "balance",
        component: () => import("@/views/user/balance.vue"),
      },
      {
        path: "/integral",
        name: "integral",
        component: () => import("@/views/user/integral.vue"),
      },
      {
        path: "/works",
        name: "works",
        component: () => import("@/views/user/works.vue"),
      },
      {
        path: "/shopDecoration",
        name: "shopDecoration",
        component: () => import("@/views/user/shopDecoration.vue"),
      },
      {
        path: "/busInfo",
        name: "busInfo",
        component: () => import("@/views/user/busInfo.vue"),
      },
      {
        path: "/chongzhi",
        name: "chongzhi",
        component: () => import("@/views/user/chongzhi.vue"),
      },
      {
        path: "/chongzhi2",
        name: "chongzhi2",
        component: () => import("@/views/user/chongzhi2.vue"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("@/views/user/order.vue"),
      },
      {
        path: "/payment_password",
        name: "payment_password",
        component: () => import("@/views/user/payment_password.vue"),
      },
      {
        path: "/login_password",
        name: "login_password",
        component: () => import("@/views/user/login_password.vue"),
      },
      {
        path: "/journal",
        name: "journal",
        component: () => import("@/views/user/journal.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
