import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import baseUrl from './request/baseUrl.js'
import axiosApi from './request/api.js'
import VueQuillEditor from 'vue-quill-editor/dist/ssr'
// 域名配置
Vue.prototype.$baseUrl = baseUrl
// 接口
Vue.prototype.$axiosApi = axiosApi
Vue.use(ElementUI)
Vue.use(VueQuillEditor)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
